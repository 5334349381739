<script>
  /** 
   * Simple Svelte Autocomplete
   * https://github.com/pstanoev/simple-svelte-autocomplete
   */
  
  import AutoComplete from "../lib/SimpleAutocomplete.svelte";
  import List from './filter_partial_list.svelte';

  /* only get keys category & title */
  let items = companies.map(el => ({ 
    category: el.category,
    title: el.title
  }));

  // const itemsCompany = items.map(function (el) { return el.category; })
  // const itemsCompanyReduced = Array.from(new Set(itemsCompany))
  
 
  /* reduce same category & combine titles */
  let mergedObj = items.reduce((acc, obj) => {
    if (acc[obj.category]) {
      acc[obj.category].title = acc[obj.category].title.isArray ? 
      acc[obj.category].title.concat(obj.title) : 
      [acc[obj.category].title].concat(obj.title);
    } else {
      acc[obj.category] = obj;
    }
      return acc;
  }, {});

  /* make an Array -> cleaup */
  let itemsArray = [];
  for (let prop in mergedObj) {
    itemsArray.push(mergedObj[prop]) 
  }

  // console.log(items )
  // console.log(itemsArray);


  let selectedItem
  let selectedCategory
  let isCardView = true
</script>








<!-- <h5>filter_autocomplete.svelte:</h5> 
isCardView: {isCardView} <br>
Selected category: {selectedCategory} <br>
<br> -->


<div class="controls"> 
  <div class="left">
    <button 
      class="buttonCard"
      on:click={() => (isCardView = true)}>
      <img src="/files/data/img_03.2020/Raster.svg" alt="" width="50" height="50">
    </button>
    
    <button 
      class="buttonList"
      on:click={() => (isCardView = false)}>
      <img src="/files/data/img_03.2020/Liste.svg" alt="" width="50" height="50">
    </button>

    <AutoComplete
      items={itemsArray}
      bind:selectedItem={selectedItem}
      bind:value={selectedCategory}
      labelFieldName="category"
      valueFieldName="category"
      keywordsFunction={item => item.title + ' ' + item.category}
      placeholder='Please select search a category.' 
    />

    <!-- <button
      class=""
      on:click={() => (selectedItem = null)}>
      <img src="/files/data/img_03.2020/Refresh_Icon.svg" alt="" width="50" height="50">
    </button> -->
  </div>
  {#if selectedCategory}
  <div class="right">
    <button
      class=""
      on:click={() => (selectedItem = null)}>
      <img src="/files/data/img_03.2020/Refresh_Icon.svg" alt="" width="50" height="50">
    </button>
  </div>
  {/if}

</div>


  <!-- <button
    class="button"
    on:click={() => (selectedItem = companies[companies.length - 1])}>
    Set test 1
  </button>

  <button
    class="button"
    on:click={() => (selectedItem = companies["0"])}>
    Set test 2
  </button>
  <br> <br> -->


<br>


<List {isCardView} {selectedCategory} />








