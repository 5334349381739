<script>
  import Card from './filter_partial_card.svelte';

  export let isCardView = undefined
  export let selectedCategory = undefined
</script>

<div class="results hovertext {isCardView === true ? 'viewCard' : 'viewList'}">
  {#if !selectedCategory }
    {#each companies as item}
      <Card {...item} {isCardView}/>
    {/each}
  {:else}
    {#each companies as item}
    {#if item.category && item.category === selectedCategory }
      <Card {...item} {isCardView}/>
    {/if}
    {/each}
  {/if}
</div>




