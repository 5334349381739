<script>
  export let id
  export let title
  export let category
  export let subheadline
  export let date
  export let datetime
  export let teaser
  export let teaserImg
  export let link
  export let target

  export let isCardView
</script>


{#if isCardView }
<div class="item ce_image">
  <figure class="image_container">
    <a 
      href="{link}" 
      title="{title}"
      target="{target === '1' ? '_blank' : 'self'}">
      {@html teaserImg}
      <div class="content-details fadeIn-bottom">
        <h4>{title}</h4>
        <div class="content-text">{@html teaser}</div>
        
      </div>
    </a>
    <figcaption class="caption">{subheadline}</figcaption>
  </figure>
</div>
{:else}
<div class="item">
    <a 
      href="{link}" 
      title="{title}"
      target="{target === '1' ? '_blank' : 'self'}">
      <h4>
        {title} 
        <!-- <span class="subheadline">{subheadline}</span> -->
      </h4> 
      <div class="subheadline">{@html subheadline}</div>
    </a>
</div>
{/if}